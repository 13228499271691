var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"AnW8zjrPZ5KP6fLlbt6SG"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  enableTracing: false,
  beforeSend: event => {
    if (
      event.exception?.values?.[0].value.includes(
        'Invariant: attempted to hard navigate to the same URL',
      )
    ) {
      return null
    }
    return event
  },
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    'TabId is undefined',
    "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
  ],
  denyUrls: [
    /googletagmanager\.com/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
})
